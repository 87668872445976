import './Section.sass';

function Section({ label, children }) {

	return (
		<section>
			<div className="label">{label}</div>
			<div className="content">{children}</div>
		</section>
	);
}

function SectionRow({ className, children }) {
	return ( <div className={`section-row ${ className }`}>{children}</div> );
}

function SectionRowList({ children }) {
	return ( <ul className="section-row-list">{children}</ul> );
}

function SectionRowListLabel({ children }) {
	return ( <div className="section-row-list-label">{children}</div> );
}

function SectionRowItem({ className, children }) {
	return ( <li className={`section-row-item ${ className }`}>{children}</li> );
}

export {
	Section,
	SectionRow,
	SectionRowList,
	SectionRowListLabel,
	SectionRowItem,
};